import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import CypherDudesArtifact from '../web3/abi/CypherDudes.json';
import { contractAddresses } from '../web3/contractsAddresses';

const InscriptionPreview = ({ _previewMessage }) => {

    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");

    const cypherDudesReadContract = new ethers.Contract(
        contractAddresses.CypherDudes,
        CypherDudesArtifact.abi,
        infuraProvider
    );


    useEffect(() => {
        const container = document.getElementById('incriptionPreview').children[0].children[0].children[0].firstChild;
        container.innerHTML = _previewMessage;
    }, []);

    return (
        <div className='previewInscriptionContainer'>
            <svg id='incriptionPreview' baseProfile='full' viewBox="0 0 475 475" version="1.1" overflow="visible" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsev="http://www.w3.org/2001/xml-events" xmlnsXlink="http://www.w3.org/1999/xlink" >
                <foreignObject x="0" y="0" width="475px" height="475px">
                    <html xmlns="http://www.w3.org/1999/xhtml">
                        <div style={{ backgroundColor: '#0000FF', width: '100%', fontFamily: 'Andale Mono', fontSize: '7px', color: '#ffffff' }}>
                            <div class="data-container" id="decodedData" style={{ width: 'unset', scrollbarColor: '#00ff00 #ffffff00', scrollbarWidth: 'thin', overflowY: 'auto', wordWrap: 'break-word' }}></div>
                        </div>
                    </html>
                </foreignObject>
            </svg>
        </div>
    )
}
export default InscriptionPreview;