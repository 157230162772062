import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { ethers } from "ethers";
import CypherDudesArtifact from '../web3/abi/CypherDudes.json';
import CypherDudesBitArtifact from '../web3/abi/CypherDudesBit.json';
import { contractAddresses } from '../web3/contractsAddresses';
import CollectionTokenCard from "../components/collectionTokenCard";
import { v4 as uuidv4 } from 'uuid';
import { superAdminAddress } from '../web3/adminAddresses';
import styled from 'styled-components';

import firebase from 'firebase/app';
import 'firebase/storage'

import Background from "../components/background";

import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import { tokenDB } from "../App";

const PageLink = styled(Link)`
  text-decoration: none;
  cursor: pointer
  &:focus, &:visited, &:link, &:active {
      text-decoration: none;
  }
`;

const utilities = require("../components/utilities");

const Collection = ({ paginator }) => {

    const params = useParams();
    const pagination = parseInt(params.paginator);
    const previous = pagination-1;
    const next = pagination +1;

    const [supply, setSupply] = useState(0);
    const [tokens, setTokens] = useState([]);
    const [txError, setTxError] = useState(undefined);
    const [loadStatus, setLoadStatus] = useState(false);
    const [admin, setAdmin] = useState(false);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);
    const openProvider = new ethers.BrowserProvider(window.ethereum);
    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");

    const cypherDudesReadContract = new ethers.Contract(
        contractAddresses.CypherDudes,
        CypherDudesArtifact.abi,
        infuraProvider
    );

    const cypherDudesBitReadContract = new ethers.Contract(
        contractAddresses.CypherDudesBit,
        CypherDudesBitArtifact.abi,
        infuraProvider
    );

    var duration = 1000,
        element,
        step,
        frames = '00000001,00000010,00000100,00001000,00010000,00100000,10000000,00000000'.split(',');

    step = function (timestamp) {
        var frame = Math.floor(timestamp * frames.length / duration) % frames.length;
        if (!element) {
            element = window.document.getElementById('spinner');
        };
        element.innerHTML = frames[frame];
        return window.requestAnimationFrame(step);
    }

    window.requestAnimationFrame(step);

    useEffect(() => {
        
        getTokens()
        if (address === superAdminAddress) {
            setAdmin(true);
        }
    }, []);

    useEffect(()=>{
        if (address === superAdminAddress) {
            setAdmin(true);
        }
    }, [address])

    useEffect(() => {
        if (loadStatus) {
            setTimeout(() => {
                saveThumbnail();
            }, 1000);
        }
    }, [loadStatus]);

    const getTokens = async () => {
        let fetchedTokens = [];
        try {
            setTokens([]);
            const _supply = Number(await cypherDudesReadContract.totalSupply());
            const _bitSupply = Number(await cypherDudesBitReadContract.totalSupply());
            const sup = _supply + _bitSupply;
            setSupply(sup);

            if (sup === 0) {
                return;
            }
            for (let i = pagination * 20; i < (pagination * 20 + 20); i++) {
                if (i > sup - 1) {
                    return;
                }
                if (i < 1728) {
                    try {
                        let tokenURI = await cypherDudesReadContract.tokenURI(i);
                        const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                        const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                        const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                        const tokenEntry = {
                            id: uuidv4(),
                            tokenId: i.toString(),
                            name: tokenURIJSONDecoded.name,
                            traits: tokenURIJSONDecoded.attributes,
                            animationURL: animationURL
                        }
                        //tokenDB.doc(tokenEntry.id).set(tokenEntry);
                        fetchedTokens.push(tokenEntry);
                        setTokens([...fetchedTokens]);//
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    try {
                        let tokenURI = await cypherDudesBitReadContract.tokenURI(i);
                        const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                        const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                        const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                        const tokenEntry = {
                            id: uuidv4(),
                            tokenId: i.toString(),
                            name: tokenURIJSONDecoded.name,
                            traits: tokenURIJSONDecoded.attributes,
                            animationURL: animationURL
                        }
                        //tokenDB.doc(tokenEntry.id).set(tokenEntry);
                        fetchedTokens.push(tokenEntry);
                        setTokens([...fetchedTokens]);//
                    } catch (error) {
                        console.log(error)
                    }
                }
                
            }

        } catch (error) {
            console.log(error);
            setTxError(error);
        }
    }

    const saveThumbnail = async () => {
        const loadedDudes = Array.from(document.getElementsByClassName('MytokenCard'));
        for (let i = 0; i < loadedDudes.length; i++) {
            var iframe = loadedDudes[i].firstChild;
            var _tokenId = iframe.getAttribute('id');
            console.log(_tokenId);
            var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            var _svg1 = iframeDocument.getElementById('cypherdude').cloneNode(true);
            var el = document.createElement("div");
            el.appendChild(_svg1);
            var _svgScript = el.firstChild.getElementsByTagName('script');
            _svgScript[0].remove();
            var _doc = el.firstChild.getElementById('doc');
            _doc.remove();
            var cli = el.firstChild.getElementById('CLI');
            cli.remove()
            var _svg2 = el.innerHTML;
            try {
                uploadToStorage(_tokenId, _svg2);
            } catch (error) {
                console.log(error)
            }
        }
    }

    const uploadToStorage = (tokenID, data) => {
        const storage = firebase.storage();
        const storageRef = storage.ref();
        const filename = `${tokenID.toString()}.svg`
        const fileRef = storageRef.child(filename);

        fileRef.putString(data)
            .then((snapshot) => {
                console.log('data upload OK')
            })
            .catch((error) => {
                console.error('upload error :', error)
            })
    }

    return (
        <div>
            <Background />
            <div className="connectInfo"><p>{isConnected ? '' : 'Connect your wallet to view the current state of the collection'}</p></div>
            {admin ?
                <div className="adminTools">
                    <button onClick={saveThumbnail} className="uxBtn">SaveThumbnails</button>
                </div>
                :
                ''
            }
            <div className="adminTools">
                <div className="Paginators">
                    {pagination < 1 ?
                        '' :
                        <PageLink reloadDocument to={`/collection/${previous}`}>
                            <button className="uxBtn">{`${(previous) * 20} to ${(pagination * 20) - 1}`}</button>
                        </PageLink>
                    }
                    <p className="currentPagination">{`${(pagination) * 20} to ${Math.min((next * 20) - 1,supply-1)}`}</p>
                    {((next * 20) - 1) > supply ?
                        '' :
                        <PageLink reloadDocument to={`/collection/${next}`}>
                            <button className="uxBtn">{`${(next * 20)} to ${Math.min((next*20)+19, supply-1)}`}</button>
                        </PageLink>
                    }
                </div>
            </div>

            <div className="collectionContainer">

                {tokens.length < 1 ? `Fetching Cypherdudes collection ` : tokens.map((token, id) => {
                    document.getElementById('spinner').style.display = "none";
                    return <CollectionTokenCard tokens={token} key={id} />
                })}
                <span id="spinner">Loading...</span>
            </div>
        </div>
    )
}

export default Collection;