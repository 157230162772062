import React from "react";
import LiveTokenCard from "../components/liveTokenCard";
import MediaQuery from 'react-responsive';
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import CypherDudesArtifact from '../web3/abi/CypherDudes.json';
import CypherDudesBitArtifact from '../web3/abi/CypherDudesBit.json';
import { contractAddresses } from '../web3/contractsAddresses';
import { v4 as uuidv4 } from 'uuid';
import { Alchemy, Network} from "alchemy-sdk";
import { breakpoints } from "../components/constants";
import { useParams } from 'react-router-dom';
import { alchemySettings } from "../App";
import { ReactComponent as Loader } from '../components/svg/loader.svg';
import { ReactComponent as Connect } from '../components/svg/connect.svg';

import firebase from 'firebase/app';
import 'firebase/storage'

import { tokenDB, thumbnailDB } from "../App";

import Background from "../components/background";

import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";


const utilities = require("../components/utilities");


const MyCypherdudes = () => {
    const alchemy = new Alchemy({
        apiKey : process.env.ALCHEMY_ETH_KEY,
        network: Network.ETH_MAINNET,
      });

    const [supply, setSupply] = useState(0);
    const [tokens, setTokens] = useState([]);
    const [balance, setBalance] = useState(0);
    const [bitBalance, setBitBalance] = useState(0);
    const [txError, setTxError] = useState(undefined);
    const [loadStatus, setLoadStatus] = useState(false);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);
    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");
    const openProvider = new ethers.BrowserProvider(window.ethereum);

    const cypherDudesReadContract = new ethers.Contract(
        contractAddresses.CypherDudes,
        CypherDudesArtifact.abi,
        provider
    );

    const cypherDudesBitReadContract = new ethers.Contract(
        contractAddresses.CypherDudesBit,
        CypherDudesBitArtifact.abi,
        infuraProvider
    );

    useEffect(() => {
        _getTokensOfOwner()
    }, []);

    useEffect(() => {
        setTimeout(() => {
            _getTokensOfOwner()
        }, 1000);
    }, [address]);

    useEffect(() => {
        if (loadStatus) {
            setTimeout(() => {
                //saveThumbnail();
            }, 1000);
        }
    }, [loadStatus]);

    const queryOptions = {
        contractAddresses: [contractAddresses.CypherDudes],
        omitMetadata: true,
    }
    const bitQueryOptions = {
        contractAddresses: [contractAddresses.CypherDudesBit],
        omitMetadata: true,
    }

    const _getTokensIdfromOwner = async () => {
        const tokens = await alchemy.nft.getNftsForOwner(address, queryOptions);
        return tokens.ownedNfts;
    }

    const _getBitTokensIdfromOwner = async () => {
        const tokens = await alchemy.nft.getNftsForOwner(address, bitQueryOptions);
        return tokens.ownedNfts;
    }

    const _getTokensOfOwner = async () => {
        if (!isConnected) {
            return;
        }
        try {
            const ownedTokens = await _getTokensIdfromOwner();
            const ownedBitTokens = await _getBitTokensIdfromOwner();
            setBalance(ownedTokens.length);
            setBitBalance(ownedBitTokens.length);
            setTokens([]);

            let fetchedTokens = [];
            for (let i = 0; i < ownedTokens.length; i++) {
                try {
                    let tokenURI = await cypherDudesReadContract.tokenURI(ownedTokens[i].tokenId);
                    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                    const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                    const tokenEntry = {
                        id: uuidv4(),
                        tokenId: ownedTokens[i].tokenId.toString(),
                        name: tokenURIJSONDecoded.name,
                        traits: tokenURIJSONDecoded.attributes,
                        animationURL: animationURL
                    }
                    fetchedTokens.push(tokenEntry);
                    setTokens([...fetchedTokens]);
                } catch (error) {
                    console.log(error)
                }
            }
            for (let i = 0; i < ownedBitTokens.length; i++) {
                try {
                    let tokenURI = await cypherDudesBitReadContract.tokenURI(ownedBitTokens[i].tokenId);
                    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                    const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                    const tokenEntry = {
                        id: uuidv4(),
                        tokenId: ownedBitTokens[i].tokenId.toString(),
                        name: tokenURIJSONDecoded.name,
                        traits: tokenURIJSONDecoded.attributes,
                        animationURL: animationURL
                    }
                    fetchedTokens.push(tokenEntry);
                    setTokens([...fetchedTokens]);
                } catch (error) {
                    console.log(error)
                }
            }
            setLoadStatus(true);
        } catch (error) {
            console.log(error);
            setTxError(error);
        }
    }

    const uploadToStorage = (tokenID, data) => {
        const storage = firebase.storage();
        const storageRef = storage.ref();
        const filename = `${tokenID.toString()}.svg`
        const fileRef = storageRef.child(filename);

        fileRef.putString(data)
            .then((snapshot) => {
                console.log('data upload OK')
            })
            .catch((error) => {
                console.error('upload error :', error)
            })
    }

    return (
        <div>
            <Background />
            <div className="tokenContainer">
                {isConnected ? tokens.length < 1 ?
                    <div>
                        <p className="highlight">Fetching your Cypherdudes from the blockchain, This can take a while...</p>
                        <div className="loader">
                            <Loader />
                        </div>
                    </div>
                    :
                    <div>
                        <p className="highlight">{`You own ${balance+bitBalance} cypherdude${(balance+bitBalance) < 2 ? '' : 's'}`}</p>
                        {
                            tokens.map((token, id) => {
                                return <LiveTokenCard tokens={token} key={id} />
                            })
                        }
                    </div>
                    :
                    <div>
                        <p className="highlight">Connect your wallet to browse your Cypherdudes</p>
                        <div className="loader">
                            <Connect />
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default MyCypherdudes;