import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { ethers } from "ethers";
import CypherDudesArchivesArtifact from '../web3/abi/CypherDudesArchives.json';
import { contractAddresses } from '../web3/contractsAddresses';
import ArchivesCollectionTokenCard from "../components/archivesCollectionTokenCard";
import { v4 as uuidv4 } from 'uuid';
import { superAdminAddress } from '../web3/adminAddresses';
import styled from 'styled-components';

import Background from "../components/background";

import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

const PageLink = styled(Link)`
  text-decoration: none;
  cursor: pointer
  &:focus, &:visited, &:link, &:active {
      text-decoration: none;
  }
`;

const utilities = require("../components/utilities");

const ArchivesCollection = ({ paginator }) => {

    const params = useParams();
    const pagination = parseInt(params.paginator);
    const previous = pagination-1;
    const next = pagination +1;

    const [supply, setSupply] = useState(0);
    const [tokens, setTokens] = useState([]);
    const [txError, setTxError] = useState(undefined);
    const [loadStatus, setLoadStatus] = useState(false);
    const [admin, setAdmin] = useState(false);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);
    const openProvider = new ethers.BrowserProvider(window.ethereum);
    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");

    const cypherDudesArchivesReadContract = new ethers.Contract(
        contractAddresses.CypherDudesArchives,
        CypherDudesArchivesArtifact.abi,
        infuraProvider
    );

    var duration = 1000,
        element,
        step,
        frames = '00000001,00000010,00000100,00001000,00010000,00100000,10000000,00000000'.split(',');

    step = function (timestamp) {
        var frame = Math.floor(timestamp * frames.length / duration) % frames.length;
        if (!element) {
            element = window.document.getElementById('spinner');
        };
        element.innerHTML = frames[frame];
        return window.requestAnimationFrame(step);
    }

    window.requestAnimationFrame(step);

    useEffect(() => {
        
        getTokens()
        if (address === superAdminAddress) {
            setAdmin(true);
        }
    }, []);

    useEffect(()=>{
        if (address === superAdminAddress) {
            setAdmin(true);
        }
    }, [address])


    const getTokens = async () => {
        let fetchedTokens = [];
        try {
            setTokens([]);
            const _supply = Number(await cypherDudesArchivesReadContract.totalSupply());
            setSupply(_supply);

            if (_supply === 0) {
                return;
            }
            for (let i = pagination * 20; i < (pagination * 20 + 20); i++) {
                if (i > _supply - 1) {
                    return;
                }
                try {
                    let tokenURI = await cypherDudesArchivesReadContract.tokenURI(i);
                    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                    const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                    const tokenEntry = {
                        id: uuidv4(),
                        tokenId: i.toString(),
                        name: tokenURIJSONDecoded.name,
                        traits: tokenURIJSONDecoded.attributes,
                        animationURL: animationURL
                    }
                    fetchedTokens.push(tokenEntry);
                    setTokens([...fetchedTokens]);//
                } catch (error) {
                    console.log(error)
                }
            }

        } catch (error) {
            console.log(error);
            setTxError(error);
        }
    }

    return (
        <div>
            <Background />
            <div className="connectInfo"><p>{isConnected ? '' : 'Connect your wallet to view the current state of the Archives collection'}</p></div>
            <div className="adminTools">
                <div className="Paginators">
                    {pagination < 1 ?
                        '' :
                        <PageLink reloadDocument to={`/archives_collection/${previous}`}>
                            <button className="uxBtn">{`${(previous) * 20} to ${(pagination * 20) - 1}`}</button>
                        </PageLink>
                    }
                    <p className="currentPagination">{`${(pagination) * 20} to ${Math.min((next * 20) - 1,supply-1)}`}</p>
                    {((next * 20) - 1) > supply ?
                        '' :
                        <PageLink reloadDocument to={`/archives_collection/${next}`}>
                            <button className="uxBtn">{`${(next * 20)} to ${Math.min((next*20)+19, supply-1)}`}</button>
                        </PageLink>
                    }
                </div>
            </div>

            <div className="collectionContainer">

                {tokens.length < 1 ? `Fetching Cypherdudes Archives collection ` : tokens.map((token, id) => {
                    document.getElementById('spinner').style.display = "none";
                    return <ArchivesCollectionTokenCard tokens={token} key={id} />
                })}
                <span id="spinner">Loading...</span>
            </div>
        </div>
    )
}

export default ArchivesCollection;